import { Routes, Route } from "react-router-dom";
import Pane from "./pages/Pane";
import "./styles/global.scss";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="settings" element={<Pane />}>
          <Route path=":source" element={<Pane />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
