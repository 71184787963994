import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./pane.module.scss";

function Pane() {
    const params = useParams();
    const source = params.source;
    const [config, setConfig] = useState(null);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        async function fetchConfig() {
            const res = await fetch(`/config/${source}`);
            if (res.status === 200) {
                setConfig(await res.json());
            }
            //setConfig({ "endpoint": "https://theshade.no/wp-json/wc/v3/", "secret": "cs_d260a9fe5f54b988894ecc2bdfa4eee565ee9a86", "attributes": [{ "type": "variation", "name": "gallery", "label": "Gallery" }, { "type": "product", "name": "size_and_fit", "label": "Size and fit" }] });
        }
        fetchConfig();
    }, [source]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const fields = e.target.elements;
        let values = {};

        Object.keys(config).forEach(key => {
            if (typeof config[key] === 'object') {
                let arrayValues = [];
                const arrayFields = Array.from(fields).filter(field => field.name.startsWith(`${key}-`));

                for (let i = 0; i < arrayFields.length; i++) {
                    const field = arrayFields[i].name.split('-');
                    let attr = arrayValues[field[1]] || {};
                    attr[field[2]] = arrayFields[i].value;

                    arrayValues[field[1]] = attr;
                }

                values[key] = arrayValues;
            }
            else {
                values[key] = fields[key].value;
            }
        });

        console.log(values);

        return await fetch('/config', {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(values)
        }).then((res) => {
            console.log("Success", res);
        }).catch(() => {
            console.log("Failed");
        });
    }

    if (!config) return (<p>Loading...</p>);

    return (
        <form className={styles.settings} onSubmit={handleSubmit}>

            {Object.keys(config).map((key, i) => {

                if (typeof config[key] === 'object') {
                    return (
                        <fieldset key={`config-${i}`}>
                            <legend>{key}</legend>
                            <div className={styles.multiline}>
                                {Object.keys(config[key][0]).map(label => (<p key={label}>{label}</p>))}
                            </div>
                            {config[key].map((attr, j) => (
                                <div key={`config-${i}-${j}`} className={styles.multiline}>
                                    {Object.keys(attr).map(attrKey => (
                                        <p key={`config-${i}-${j}-${attrKey}`}>
                                            <input
                                                type='text'
                                                name={`${key}-${j}-${attrKey}`}
                                                id={`${key}-${j}-${attrKey}`}
                                                defaultValue={attr[attrKey]}
                                                onChange={() => setIsChanged(true)}
                                            />
                                        </p>
                                    ))}
                                </div>
                            ))}
                            <div key={`config-${i}-new`} className={styles.multiline}>
                                {Object.keys(config[key][0]).map(attrKey => (
                                    <p key={`config-${i}-new-${attrKey}`}>
                                        <input
                                            type='text'
                                            name={`${key}-${config[key].length}-${attrKey}`}
                                            id={`${key}-${config[key].length}-${attrKey}`}
                                            onChange={() => setIsChanged(true)}
                                        />
                                    </p>
                                ))}
                            </div>
                        </fieldset>
                    )
                }

                return (
                    <p key={`config-${i}`}>
                        <label htmlFor={key}>{key}</label>
                        <input
                            type="text"
                            name={key}
                            id={key}
                            defaultValue={config[key]}
                            onChange={() => setIsChanged(true)}
                        />
                    </p>
                )
            })}

            {isChanged && <input type="submit" value="Save" />}

        </form>
    );
}

export default Pane;